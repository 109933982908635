import React, { useEffect } from 'react'
import { SeoHelmet } from '../common/Functions'
import { MapInstead, DBG, UpdatingStruct, N1Problem, FeatureFlag, SoftDelete } from '../common/Tips'
import { Link } from '@reach/router'

function DailyTakeaways() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, [])

  return (
    <main className="px-6 mx-auto max-w-screen-lg lg:px-10">
      <SeoHelmet title="Tips" description="Written by yours truly, here are some of the stories that I'd like to share. Most of them are about the things I've used or had problems using, in my years of being a developer. Hope I can help someone with these." link="/tips" />
      <div className="container flex flex-col flex-wrap items-center justify-between px-0 py-10 mx-auto md:flex-row">
        <div className="z-50 flex flex-col w-full items-left">
          <Link to="/#tips" className="inline-flex text-base text-gray-600 focus:outline-none items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mr-3 -ml-2 text-gray-600">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
            Back
          </Link>
          <div className="mt-10 content lg:mt-16 md:mt-12 sm:mt-8">
            <ul>
              <li className="pb-10 mb-10 border-b-2 last:border-b-0">
              <SoftDelete />
              </li>
              <li className="pb-10 mb-10 border-b-2 last:border-b-0">
              <FeatureFlag />
              </li>
              <li className="pb-10 mb-10 border-b-2 last:border-b-0">
              <N1Problem />
              </li>
              <li className="pb-10 mb-10 border-b-2 last:border-b-0">
                <UpdatingStruct />
              </li>
              <li className="pb-10 mb-10 border-b-2 last:border-b-0">
                <DBG />
              </li>
              <li className="pb-10 mb-10 border-b-2 last:border-b-0">
                <MapInstead />
              </li>
              </ul>
          </div>
        </div>
      </div>
    </main>
  )
}

export default DailyTakeaways
