import React, { useState } from 'react'
import { SeoHelmet, LazyLoad } from '../common/Functions'
import { Link } from '@reach/router'

function Home() {

  const [isSkillSpectrumActive, setIsSkillSpectrumActive] = useState(true);
  const [isInvolvementsActive, setIsInvolvementsActive] = useState(true);

  return (
    <main className="px-6 mx-auto max-w-screen-lg lg:px-10">
      <SeoHelmet title="Software Engineer" description="Software Engineer - since 2015" link="/" />
      <div className="container flex flex-col flex-wrap items-center justify-between px-0 py-10 mx-auto md:flex-row">
        <div className="flex flex-col w-full items-left">
          <div className="mt-8 content lg:mt-12 md:mt-10 sm:mt-8">
            <div className="flex flex-col items-center md:flex-row">
              <div className="md:hidden rounded-full bg-theme-color lg:mr-12 md:mr-10 overflow-hidden w-16 h-16 mb-4">
                <LazyLoad className="inline-auto alvin-dp" src={require('../common/images/alvin_dp_3.png')} alt="Alvin Profile"/>
              </div>
              <div>
                <h1 className="mb-0 text-3xl font-bold text-center md:text-left lg:text-4xl md:text-3xl">
                  Alvin Rapada
                </h1>
                <p className="text-sm font-bold text-center md:text-left text-theme-color md:flex md:flex-row md:items-center mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-3 text-gray-600 hidden md:block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
</svg>
                  <span className="font-medium text-theme-color"> 
                    Software Engineer <span className="text-gray-600"> &mdash; from Philippines </span>
                  </span>
                </p>
                <p className="text-sm font-bold text-center md:text-left text-theme-color md:flex md:flex-row md:items-center mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-3 text-gray-600 hidden md:block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                  </svg>
                  <span className="font-medium text-theme-color hover:underline"><a href="mailto:alvnrapada@gmail.com"> alvnrapada@gmail.com</a></span>
                </p>
                <p className="hidden text-sm font-bold text-center md:text-left text-theme-color flex flex-row items-center mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#2ecc71" className="h-3 w-3 mr-3 ">
                    <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                  </svg>
                  <span className="font-medium  text-theme-color"> Open for Part Time Remote Work </span>
                </p>
              </div>

            </div>
          </div>
          <div className="mt-10 content lg:mt-12 md:mt-10 sm:mt-8">
            <div className="flex flex-row justify-between list">
              <p className="text-base mb-3 text-center md:text-left text-gray-600">More than <span className="font-semibold text-black underline">7 years</span> of robust experience in <span className="font-semibold text-black underline">Web and API Development</span> across diverse projects, both small and large, within the <span className="font-semibold text-black underline">Phoenix/Elixir</span> ecosystem. <span className="font-semibold text-black underline">Led a cross-functional team</span> of 8-10 frontend and backend developers, along with QA testers on a start-up company here in the Philippines.
                </p>
            </div>
          </div>

          <div className="accordion mt-10 lg:mt-12 md:mt-10 sm:mt-8">
            <div className="accordion-item">
              <div
                className="accordion-title flex items-center cursor-pointer"
                onClick={() => setIsSkillSpectrumActive(!isSkillSpectrumActive)}
              >
                <p className="text-base font-bold text-center md:text-left flex flex-row items-center">

                  <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-3 text-gray-600" fill={isSkillSpectrumActive ? 'none' : 'currentColor'} viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 3.104v5.714a2.25 2.25 0 0 1-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 0 1 4.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0 1 12 15a9.065 9.065 0 0 0-6.23-.693L5 14.5m14.8.8 1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0 1 12 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5" />
</svg>

                  <span className="font-bold"> 
                    skill spectrum
                  </span>
                </p>
              </div>
              {isSkillSpectrumActive && 
              <div className="content mb-6" id="technologies">
                <p className="my-3 text-gray-500 text-xs">
                  Core Competencies
                </p>
                <div className="flex flex-col md:flex-row justify-between my-3 list">
                  <ul className="w-1/3">
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://www.phoenixframework.org/" target="blank">Phoenix Framework</a></li>
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://elixir-lang.org/" target="blank">Elixir</a></li>
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://tailwindcss.com/" target="blank">TailwindCSS</a></li>
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://github.com/alpinejs/alpine" target="blank">AlpineJS</a></li>
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://alvinrapada.medium.com/" target="blank">LiveView</a></li>
                  </ul>
                  <ul className="w-1/3">
                    <li className="mb-3 text-black hover:underline font-bold"><a href="https://petal.build" target="blank">petal.build</a></li>
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://alvinrapada.medium.com/crud-graphql-with-elixir-phoenix-e6bee9b6bec2" target="blank">Absinthe/GraphQL API</a></li>
                    <li className="mb-3 text-gray-600"><a href="https://stripe.com/docs/api/subscriptions" target="blank" className="hover:underline">Stripe Integration</a></li>
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://sendbird.com/docs/business-messaging/guide/v2/overview" target="blank">Sendbird Integration</a></li>
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://www.twilio.com/docs" target="blank">Twilio Integration</a></li>
                  </ul>
                  <ul className="w-1/3">
                    <li className="mb-3 text-gray-600 hover:underline">Oban/Quantum</li>
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://alvinrapada.medium.com/creating-unique-constraint-index-ecto-migration-elixir-37146722e593" target="blank">Postgres/Ecto/Hex</a></li>
                    <li className="mb-3 text-gray-600 hover:underline">GenServer/ETS/Cachex</li>
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://powauth.com/" target="blank">POW Auth</a></li>
                  </ul>
                </div>
                <div className="flex flex-col justify-between my-3 list hidden">
                  <p className="mb-3 text-gray-500 text-xs">Machine Learning</p>
                  <div className="w-full flex flex-col md:flex-row">
                    <ul className="w-1/3">
                      <li className="mb-3 text-gray-600 hover:underline">Nx</li>
                      <li className="mb-3 text-gray-600 hover:underline">Bumblebee</li>
                      <li className="mb-3 text-gray-600 hover:underline">EXLA</li>
                    </ul>
                    <ul className="w-1/3">
                      <li className="mb-3 text-gray-600 hover:underline">Axon</li>
                      <li className="mb-3 text-gray-600 hover:underline">Scholar</li>
                      <li className="mb-3 text-gray-600 hover:underline">Explorer</li>
                    </ul>
                    <ul className="w-1/3">
                      <li className="mb-3 text-gray-600 hover:underline">TorchX</li>
                      <li className="mb-3 text-gray-600 hover:underline">Livebook</li>
                      <li className="mb-3 text-gray-600">Transformer</li>
                    </ul>
                  </div>
                </div>
                <p className="mb-3 text-gray-500 text-xs">
                  Others 
                </p>
                <div className="flex flex-col md:flex-row justify-between mt-3 list">
                  <ul className="w-1/3">
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://github.com/ueberauth/ueberauth" target="blank">Überauth</a></li>
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://github.com/beam-community/ex_machina" target="blank">ExMachina</a></li>
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://github.com/tompave/fun_with_flags" target="blank">FunWithFlags</a></li>

                  </ul>
                  <ul className="w-1/3">
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://www.activecampaign.com/api/overview.php" target="blank">Activecampaign</a></li>
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://developers.klaviyo.com/en/reference/api_overview" target="blank">Klaviyo</a></li>
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://developers.beehiiv.com/docs/v2" target="blank">Beehiiv</a></li>
                  </ul>
                  <ul className="w-1/3">
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://fly.io/" target="blank">Fly.io</a></li>
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://render.com/" target="blank">Render</a></li>
                    <li className="mb-3 text-gray-600 hover:underline"><a href="https://www.netlify.com/" target="blank">Netlify</a></li>
                  </ul>
                </div>
              </div>
              }
            </div>
          </div>

          <div className="accordion mt-4">
            <div className="accordion-item">
              <div
                className="accordion-title flex items-center cursor-pointer"
                onClick={() => setIsInvolvementsActive(!isInvolvementsActive)}
              >
                <p className="text-base font-bold text-center md:text-left flex flex-row items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-3 text-gray-600" fill={isInvolvementsActive ? 'none' : 'currentColor'} viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                  </svg>
                  <span className="font-bold"> 
                   involvements 
                  </span>
                </p>
              </div>
              {isInvolvementsActive && 
              <div className="content mb-6" id="experiences&involvements">
                <div className="flex flex-col justify-between mt-3 list">
                  <p className="mb-3 text-gray-500 text-xs">Companies</p>
                  <ul className="mb-3">
                    <li className="mb-3 text-gray-600">Phoenix/Elixir Developer &mdash; <a href="https://blayze.io/" target="blank" className="font-bold text-theme-color underline">Blayze</a> <span className="text-gray-500 text-xs">(Current - since January 2021)</span>
                    </li>
                    {/* <li className="mb-3 text-gray-600">Phoenix/Elixir Developer &mdash; <a href="https://fino.group/" target="blank" className="font-bold text-theme-color underline">Fino Data Services GmbH</a> <span className="text-gray-500 text-xs">(Part-Time - since Sept 2024)</span> */}
                    {/* </li> */}
                    <li className="mb-3 text-gray-600">Lead Software Engineer &mdash; <a href="https://pragtechnologies.com" target="blank" className="font-bold text-theme-color underline">Pragtechnologies</a> <span className="text-gray-500 text-xs">(Aug 2015 - Oct 2021)</span>
                    </li>
                    <li className="mb-3 text-gray-600">Lead Software Engineer &mdash; <a href="https://www.seeyoudoc.com/" target="blank" className="font-bold text-theme-color underline">SeeYouDoc</a> <span className="text-gray-500 text-xs">(Aug 2015 - Oct 2021)</span>
                    </li>
                  </ul>
                  <p className="mb-3 text-gray-500 text-xs">Projects & Roles</p>
                  <ul className="mb-3">
                    <li className="mb-3 text-gray-600"> Elixir Developer &mdash; <a href="https://blayze.io/" target="blank" className="font-bold text-theme-color underline">Blayze App</a> <span className="text-gray-500 text-xs">(4 year project)</span>
                    </li>
                    <li className="mb-3 text-gray-600"> Lead Elixir Developer &mdash; <a href="https://www.seeyoudoc.com/" target="blank" className="font-bold text-theme-color underline">SeeYouDoc App</a> <span className="text-gray-500 text-xs">(4 year project)</span>
                    </li>
                    <li className="mb-3 text-gray-600">Elixir Developer &mdash; <a href="https://loisysalvacion.netlify.app/works/nac-cmc-omt" target="blank" className="font-bold text-theme-color underline">NAC CMC-OMT</a> <span className="text-gray-500 text-xs">(3 year project)</span>
                    </li>
                    <li className="mb-3 text-gray-600"> Fullstack Developer &mdash; <a href="https://www.textstack.io/" target="blank" className="font-bold text-theme-color underline">TextStack</a> <span className="text-gray-500 text-xs">(2 year project)</span>
                    </li>
                    <li className="mb-3 text-gray-600">Front-End Developer &mdash; <a href="https://loisysalvacion.netlify.app/works/aims" target="blank" className="font-bold text-theme-color underline">AIMS</a> <span className="text-gray-500 text-xs">(1 year project)</span>
                    </li>
                  </ul>

                  <p className="mb-3 text-gray-500 text-xs">hackathons</p>
                  <ul>
                    <li className="mb-3 text-gray-600">ADB-AIM Hack <em>(Finalist)</em> Aug 2018 &mdash; <a href="https://ultrahack.org/" target="blank" className="font-bold text-theme-color underline">Ultrahack</a></li>
                    <li className="mb-3 text-gray-600">Turku Business Region Ultrahack <em>(2nd Place)</em> Oct 2018 &mdash; <a href="https://ultrahack.org/" target="blank" className="font-bold text-theme-color underline">Ultrahack</a></li>
                  </ul>
                </div>
              </div>
              }
            </div>
          </div>


          <Link id="stories" to="/stories" className="mt-4 text-base font-bold text-center md:text-left flex flex-row items-center hover:underline">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-3 w-3 mr-3 text-gray-600">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" />
                    </svg>
            <span className="font-bold"> 
              stories
            </span>
          </Link>

          <Link id="tips" to="/tips" className="mt-4 mb-20 text-base font-bold text-center md:text-left flex flex-row items-center hover:underline">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-3 w-3 mr-3 text-gray-600">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
            </svg>
            <span className="font-bold"> 
              self tips
            </span>
          </Link>
        </div>
      </div>
    </main>
  )
}

export default Home
