import React from 'react'

import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { LazyLoad } from './Functions'

export const FeatureFlag = () => {

  return (
    <div>
      <div className="items-start text-xl font-semibold text-dark-600 hover:text-theme-color">
        Use FunWithFlags, or other feature flag libraries. 
      </div>
      <p className="mt-3 text-gray-600">
        Developers should know how to use feature flagging tools like funwithflags rather than manually disabling features. But in both cases please don't forget to enable it before or while going live.
      </p>
      <LazyLoad className="rounded-lg overflow-hidden w-1/2 mt-6" src={require('./images/feature_flag.jpeg')} alt="Feature Flag Image"/>
    </div>
  )
}

export const N1Problem = () => {

  const code_snippet = `

  alias MyApp.{
    Author,
    Post
  }

  ❌ EXAMPLE:

  # Fetch all authors
  authors = Repo.all(Author)

  # For each author, fetch their posts (N+1 queries)
  authors_with_posts =
    authors
    |> Enum.map(fn author ->
      %{author | posts: Repo.all(from p in Post, where: p.author_id == ^author.id)}
    end)


  ✅ DO THIS INSTEAD:

  # Fetch all authors with their posts in a single query
  authors_with_posts = Repo.all(from a in Author, preload: [:posts])

  OR

  authors_with_posts =
    from(a in Author, join: p in Post, on: p.author_id == a.id)
    |> Repo.all()

  `;

  return (
    <div>
      <div className="items-start text-xl font-semibold text-dark-600 hover:text-theme-color">
        N+1 Query problem in Elixir
      </div>
      <p className="mt-3 text-gray-600">
        Occurs when you execute one query to retrieve a list of records then, for each record, you execute another query. To avoid/solve this, use Ecto's preload or join functions to load associated data in a single query.
      </p>
      <CodeSnippet code={code_snippet} />
    </div>
  )
}


export const UpdatingStruct = () => {

  const code_snippet = `
  iex> user = %User{name: "Alvin Rapada", admin: true}

  ✅ DO THIS:

  iex> user |> Map.replace!(:amdin, false)
  ** (KeyError) key :amdin not found in: %User{name: "Alvin Rapada", admin: true}.

  Did you mean:
  * :admin
  *
  ❌ INSTEAD OF THIS:

  iex> user |> Map.put(:amdin, false) # <- TYPO!!
  %{name: "Alvin Rapada", admin: true, amdin: false}
  `;

  return (
    <div>
      <div className="items-start text-xl font-semibold text-dark-600 hover:text-theme-color">
        The right way to update Elixir structs (and how not to do it)
      </div>
      <p className="mt-3 text-gray-600">
        Since structs are maps, we might be tempted to use Map.put/3, it works until you make a typo. <a target="blank" href="https://www.elixirstreams.com/tips/elixir-update-structs" className="underline">
          more info.
        </a>

      </p>
      <CodeSnippet code={code_snippet} />
    </div>
  )
}

export const DBG = () => {

  const code_snippet = `
  params =  %{name: "alvin", email: "alvin@email.com"}

  ✅ DO THIS:

  iex> dbg(params)
  [iex:11: (file)]
  params #=> %{name: "alvin", email: "alvin@email.com"}

  ❌ INSTEAD OF THIS:

  iex> IO.inspect(params)
  %{name: "alvin", email: "alvin@email.com"}
  `;
  return (
    <div>
      <div className="items-start text-xl font-semibold text-dark-600 hover:text-theme-color">
        Goodbye IO.Inspect(). Hello dbg()
      </div>
      <p className="mt-3 text-gray-600">
        Elixir 1.14 introduced <span className="font-bold">dbg</span>, a debugging helper that is equally easy to user as IO.inspect, but much more powerful. dbg is also aware of Elixir code, so it can inspect an entire pipelines.
      </p>
      <CodeSnippet code={code_snippet} />
    </div>
  )
}

export const MapInstead = () => {

  const code_snippet = `
  ✅ DO THIS:

  create_user(%{first_name: "Alvin", last_name: "Rapada", email: "alvnrapada@gmail.com"})

  def create_user(%{email: email, last_name: last_name, first_name: first_name}) do
  # function body...
  end

  ❌ INSTEAD OF THIS:

  create_user("Alvin", "Rapada", "alvnrapada@gmail.com"})

  def create_user(first_name, last_name, email) do
  # function body...
  end
  `;
  return (
    <div>
      <div className="items-start text-xl font-semibold text-dark-600 hover:text-theme-color">
        If a function requires more than two parameters, use Map instead.
      </div>
      <p className="mt-3 text-gray-600">
        Having to hop back and forth to remember the order of parameters on a function is not ideal. Key-value pairs in a map do not follow any order. 
      </p>
      <CodeSnippet code={code_snippet} />
    </div>
  )
}

export const SoftDelete = () => {

  const code_snippet = `
    ✅ Migration
      alter table(:your_records) do
        add :deleted_at, :utc_datetime
      end

      execute """
              CREATE OR REPLACE RULE soft_deletion AS ON DELETE TO your_records
              DO INSTEAD UPDATE your_records SET deleted_at = NOW() WHERE id = OLD.id AND deleted_at IS NULL;
              """,
              """
              DROP RULE IF EXISTS soft_deletion ON your_records;
              """
    ✅ Schema
    schema "your_records" do
      ...other fields
      field(:deleted_at, :utc_datetime) <- Add this
    end

    # Query
    def delete_your_record(%YourRecord{} = your_record) do
      # Since the deletion does not happen, PostgreSQL returns the information that zero rows were affected. 
      # you can opt-in and say that stale entries are expected:
      Repo.delete(your_record, allow_stale: true)
    end

    ✅ Manually excluding records with deleted_at value.
    def list_your_records do
      YourRecord
      |> deleted?(false)
      |> Repo.all()
    end

    def get_your_record!(id) do
      YourRecord
      |> deleted?(false)
      |> Repo.get!(id)
    end

    def deleted?(query, false) do
      from u in query, where: is_nil(u.deleted_at)
    end

    def deleted?(query, _), do: query
  `;
  return (
    <div>
      <div className="items-start text-xl font-semibold text-dark-600 hover:text-theme-color">
        Soft deletes with Ecto and PostgreSQL
      </div>
      <p className="mt-3 text-gray-600">
        With soft deletion rule, doing <strong>Repo.delete()</strong> will only mark your record as deleted through delete_at column, instead of effectively deleting it from the database. This tutorial only manually exclude deleted records, if you don't want to do that you can create a separate view following instructions <a target="blank" href="https://dashbit.co/blog/soft-deletes-with-ecto" className="underline">
          here.
        </a>
      </p>
      <CodeSnippet code={code_snippet} />
    </div>
  )
}

const CodeSnippet = (props) => {
  return (

    <div className="mt-6 overflow-hidden rounded-lg">
      <SyntaxHighlighter language="cpp"
        style={atomOneDark}
        customStyle={{ padding: "10px", fontSize: "13px" }}
        wrapLongLines={true}>

        {props.code}

      </SyntaxHighlighter>
    </div>

  )

}

